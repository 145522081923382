@use 'base/variables' as v;
@use 'base/mixins' as m;

footer {
    padding: 7rem 0;
    background: v.$gradient_03_g;
    h2, h3, a {
        color: v.$white;
        margin-bottom: 2rem;
        text-align: left;
    }
    p{
        color: v.$white;
        font-size: 1.4rem;
        padding-left: 2rem;
        border-left: 1px solid v.$grey_md;
        margin-bottom: 1rem;
    }
    h2, .p-logo {
        text-align: center;
    }
    h2 {
        font-size: 4rem;
        padding-bottom: 1rem;
        color: v.$white;
        border-bottom: 1px solid v.$cuart;
        @include m.desck {
            margin-top: 2rem;
        }
    }
    h3 {
        font-size: 1.8rem;
    }
    .logo_con {
        @include m.desck {
            display: grid;
            @include m.grid(2, 3rem);
        }
    }
    .p-logo{
        border: none;
        padding: 0;
        font-size: 1.4rem;
        letter-spacing: .25rem;
        font-weight: 300;
        margin: 0 0 5rem 0;
    }
    .footer{
        @include m.desck {
            display: grid;
            @include m.grid(2, 5rem);
        }
    }
    .footer-logo {
        display :flex;
        justify-content: center;
        img {
            margin: 2rem 0;
            width: 160px;
        }
    }
    .footer-enlaces {
        background: none;
        color: v.$white;
    }
    .footer-contacto{
        margin-bottom: 3rem;
    }
}
.copyRigth {
    border-top: .1rem solid v.$secondary_lt;
    background: v.$black;
    font-size: 1.2rem;
    padding: 1rem 0;
    color: v.$grey_md;
    text-align: center;
    font-weight: 100;
}