@use 'variables' as v;
@use 'base/mixins' as m;

html {
    font-size: 62.5%;
    box-sizing: border-box;
}
*, 
*:before,
*:after {
    box-sizing: inherit;
}
body {
    background: v.$generalBG;
    font-family: v.$fuente_principal;
    font-size: 1.6rem;
    &.fijar-body {
        overflow: hidden;
    }
    &.nav-fix {
        padding-top: 130px;
        @include m.tablet {
            padding-top: 103px;
        }
    }
}
p{
    color: v.$grey_dk;
    line-height: 2;
}
.contenedor {
    width: 94%;
    max-width: 120rem;
    margin: 0 auto;
}
a {
    font-weight: 300;
    text-decoration: none;
    color: v.$primary_lt;
    &:hover {
        color: v.$secondary;
        cursor: pointer;
    }
}
h1, h2, h3, p {
    margin: 0;
}
h1 {
    font-size: 4rem;
}
h2 {
    font-size: 3rem;
}
h3 {
    font-size: 2.4rem;
}

img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
    border-radius: 3px;
}

ul {
    @include m.resetear-lista;
}

.btn {
    display: inline-block;
    padding: 1rem 2rem;
    text-decoration: none;
    background: v.$primary_lt;
    font-weight: 300;
    color: v.$primary_dk;
    border-radius: 5px;
    &:hover {
        background: v.$primary_dk;
        color: v.$secondary_lt;
    }
}

.linea-division {
    width: 80%;
    height: 2px;
    background: v.$gradient_01_s;
    margin-bottom: 1.5rem;
}