@use '../base/variables' as v;
@use '../base/mixins' as m;

.header {
    z-index: 10;
    background: v.$primary_dk;
    background: v.$gradient_01_s;
    &.fijo{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: 0px 0px 20px v.$grey_dk;
    }
    .logo-bloque{
        display: flex;
        justify-content: center;
        gap: 2rem;
    }
    h1 {
        color: v.$white;
        text-align: center;
        font-size: 3rem;
        margin: 3rem 0 0 0;
        @include m.desck {
            font-size: 4rem;
            margin-top: 2rem;
        }
    }
    .contenedor-header {
        padding: 2rem 0;
        @include m.desck {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    img {
        width: 70px;
        margin: 0;
        @include m.desck {
            width: 90px;
        }
    }
}

.navegacion-principal {
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    gap: 2rem;
    @include m.desck {
        padding: 0;
    }
    a {
        color: v.$white;
        text-align: center;
        font-size: 1.6rem;
        &:hover {
            color: v.$secondary_lt;
        }
    }
}
