@use 'variables' as v;

@mixin phone {
    @media (min-width: v.$phone){
        @content;
    }
}
@mixin tablet {
    @media (min-width: v.$tablet){
        @content;
    }
}
@mixin desck {
    @media (min-width: v.$desck){
        @content;
    }
}
@mixin phone {
    @media (min-width: v.$desckXL){
        @content;
    }
}

@mixin grid($columnas, $espaciado) {
    display: grid;
    grid-template-columns: repeat($columnas, 1fr);
    gap: $espaciado;
}

@mixin resetear-lista {
    margin: 0;
    padding: 0;
    list-style: none;
}