@use 'base/variables' as v;
@use 'base/mixins' as m;

.contact_sonata_bg {
    padding: 5rem 0;
    background: url(../img/bg_ibookpor_ses_w.jpg), v.$primary_dk;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    h2 {
        color: v.$primary;
        span {
            color: v.$secondary_dk;
            text-transform: uppercase;
        }
    }
}

.contact_contenido {
    @include m.grid(2, 5rem);
}