@use 'base/variables' as v;
@use 'base/mixins' as m;

.intro { 
    background: v.$white;
    .intro_cont {
        padding: 5rem 0;
        @include m.desck {
            @include m.grid(2, 5rem);
        }
    }
}

.contenido-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
        color: v.$primary_dk;
        display: inline-block;
        border-bottom: 1px solid v.$cuart;
        padding-bottom: 1rem;
        margin: 3rem 0 1.5rem 0;
        @include m.desck {
            margin: 0 0 1.5rem 0;
        }
    }
}