@use 'base/variables' as v;
@use 'base/mixins' as m;

.cta-class {
    padding: 7rem 0;
    text-align: center;
    @include m.desck {
        @include m.grid(2, 5rem);
    }
    .icon {
        height: 10rem;
        width: auto;
        stroke: v.$primary;
        margin-bottom: 2rem;
    }
    h2 {
        font-size: 4rem;
        color: v.$primary_dk;
    }
    span {
        font-size: 5rem;
        color: v.$cuart;
    }
    p {
        font-size: 2rem;
        margin-bottom: 3rem;
    }
    .btn {
        font-size: 3rem;
        margin-bottom: 5rem;
        @include m.desck {
            margin: 0;
        }
    }
    .img-cta{
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
            margin: 0 auto;
            @include m.tablet {
                width: 70%;
            }
        }
    }
}