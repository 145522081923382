@use 'base/variables' as v;
@use 'base/mixins' as m;

.witness{
    border-radius: 0 0 10rem 0;
    background: v.$grey_lh;
    h2 {
        color: v.$secondary;
        padding: 1rem;
        border-bottom: 2px solid v.$grey_lt;
    }
}

.witness-all {
    padding: 7rem 0;
    .witness-pict,
    .witness-name,
    .witness-cargo,
    .witness-title,
    .witness-name,
    .witness-test {
        margin: 0;
    }
    .witness-pict {
        img {
            width: 8rem;
            border-radius: 50%;
            margin-bottom: 1.5rem;
        }
    }
    .witness-name {
        margin-bottom: 1.3rem;
        color: v.$primary_dk;
        font-size: 2rem;
    }
    .witness-cargo {
        margin-bottom: 1.1rem;
        color: v.$primary_lt;
        font-size: 1.6rem;
    }
    .witness-test {
        color: v.$grey_md;
        font-size: 1.4rem;
    }
}

/* estilosde materialize */

.carousel {
    min-height: 500px;
    text-align: center;
    .carousel-item {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        user-select: none;
        width: 300px;
        height: 450px;
        background: v.$white;
        border-radius: 5px;
        box-shadow: 0 0 20px rgba($color: v.$grey_md, $alpha: .5)
    }
}

.carousel .indicators .indicator-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: .4rem;
    margin: 5rem .4rem 0 .4rem ;
    background-color: rgba(194, 232, 18, .3);
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    border-radius: 50%;
  }