@use 'base/variables' as v;
@use 'base/mixins' as m;

.cards {
    border-radius: 10rem 0 10rem 0;
    background: v.$white;
    padding: 0 0 7rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imgg_touch {
    text-align: center;
    img {
        width: 30rem;
        margin: 0 auto 2rem auto;
        @include m.tablet {
            width: 40rem;
        }
        @include m.desck {
            width: 50rem;
        }
    }
}

.cards-title{
    text-align: center;
    margin: 0 auto;
    h2 {
        color: v.$primary_dk;
        font-size: 5rem;
        font-weight: 100;
        padding: 0 0 1.6rem 0;
        border-bottom: 1px solid v.$cuart;
    }
}

.single-card{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px dashed v.$grey_lt;
    h2, h3, p {
        margin-top: 2rem;
        text-align: center;
    }
    h3 {
        display: block;
        width: 30rem;
        font-size: 2rem;
        color: v.$primary_dk;
        line-height: 30px;
        @include m.desck {
            height: 100px;
            width: auto;
        }
    }
    img {
        margin: 0;
        width: 100px;
        display: block;
    }
}

.contenedor-card{
    @include m.desck {
        @include m.grid(3, 2rem);
    }
}

.card-button {
    margin: 3rem auto 0 auto;
    text-align: center;
    .card-p {
        margin: 0;
        font-size: 2.5rem;
        color: v.$secondary;
        span{
            font-weight: 700;
            color: v.$secondary_dk;
        }
    }
    .btn {
        margin-top: 1rem;
        font-size: 2.5rem;
    }
}