@use 'base/variables' as v;
@use 'base/mixins' as m;

.qualities {
    border-radius: 10rem 0 10rem 0;
    .bg_img-overL {
        display: block;
        width: 350%;
        height: auto;
        @include m.tablet {
            width: 200%;
        }
        @include m.desck {
            width: 100%;
        }
    }
    position: relative;
    background: v.$primary_dk;
    color: v.$white;
    overflow: hidden;
    height: 140rem;
    @include m.desck {
        max-height: 90rem;
    }
    .overlay-section{
        padding: 7rem 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: v.$gradient_02_a;
    }
    p, ul {
        margin: 0;
    }
}

.qualities-contenedor {
    padding: 3rem;
    @include m.desck {
        @include m.grid(2, 5rem);
    }
    img {
        margin: 5rem auto;
        width: 90%;
        @include m.tablet {
            width: 60%;
        }
        @include m.desck {
            width: 100%;
        }
    }
}

h2 {
    font-size: 5rem;
    font-weight: 100;
    text-align: center;
    margin-bottom: 4rem;
    span{
        font-size: 6rem;
        font-weight: 600;
        color: v.$secondary;
    }
}
ul {
    li {
        font-weight: 300;
        line-height: 1.5;
        display: flex;
        align-items: center;
        padding: .8rem;
        span {
            margin: 0 2rem 0 0;
        }
    }
    li:nth-child(odd){
        background: rgba(180, 205, 237, 0.2);
    }
}