@use 'base/variables' as v;
@use 'base/mixins' as m;

.news {
    display: grid;
    min-height: 60rem;
    background: url('../img/bg_ibookpor_ses_w.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    padding: 7rem 0;
    text-align: center;
    @include m.desck {
        @include m.grid(2, 5rem);
    }
}

.news-contenido {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include m.desck {
        padding-left: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .icon {
        height: 10rem;
        width: auto;
        stroke: v.$primary;
        margin-bottom: 2rem;
    }
    h2 {
        font-size: 5rem;
        font-weight: 300;
        color: v.$primary_dk;
    }
    h3 {
        font-size: 5rem;
        text-transform: uppercase;
        color: v.$cuart;
    }
    span {
        font-size: 5rem;
        color: v.$cuart;
    }
    p {
        font-size: 2rem;
        margin-bottom: 3rem;
    }
    .btn {
        font-size: 3rem;
        margin-bottom: 5rem;
        @include m.desck {
            margin: 0;
        }
    }
}

.news-img{
    img {
        margin-top: 2rem;
        display: inline-block;
        width: 130%;
        left: 0;
    }
}