// Variables -- tipografía

$fuente_principal: 'Montserrat', sans-serif;

// Variables -- colores

$generalBG: #eceff4;
$primary: #2268ca;
$primary_dk: #334d71;
$primary_lt: #b5cded;
$secondary: #7FB800;
$secondary_dk: #659400;
$secondary_lt: #C2E812;
$terceary: #FF6B6C;
$terceary_lt: #ffa6a6;
$terceary_dk: #b34141;
$cuart: #E6B027;
$grey_lh: #f6fcff;
$grey_lt: #d3dcdf;
$grey_md: #767c7e;
$grey_dk: #303338;
$black: #000;
$white: #fff;
$gradient_01_s: linear-gradient(200deg, rgba(180,205,237,1) 0%, rgba(34,104,202,1) 50%, rgba(51,77,113,1) 100%);
$gradient_01_a: linear-gradient(200deg, rgba(180,205,237,.8) 0%, rgba(34,104,202,.8) 50%, rgba(51,77,113,.8) 100%);
$gradient_02_a: linear-gradient(200deg, rgba(24, 61, 109, 0.8) 0%, rgba(22, 70, 138, 0.8) 50%, rgba(10, 39, 80, 0.8) 100%);
$gradient_03_g: linear-gradient(200deg, rgba(28, 32, 38, 1) 0%, rgb(35, 48, 69) 50%, rgba(3, 20, 44, 1) 100%);

// medidas mediaQueries

$phone: 480px;
$tablet: 768px;
$desck: 992px;
$desckXL: 1200px;

// efects

$tetx_sh: 0 0 10px rgba(0, 0, 0, 1);