@use 'base/variables' as v;
@use 'base/mixins' as m;

.galeria {
    background: v.$white;
    padding: 5rem 0;
    h3 {
        text-align: center;
    }
    ul {
        picture {
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.galeria-fotografica {
    @include m.resetear-lista;
    @include m.grid(2, 1rem);
    @include m.tablet {
        @include m.grid(5, 1rem);
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    display: grid;
    place-items: center;
    z-index: 11;

    background: rgba(0, 0, 0, .6);

    picture {
        display: block;
        max-width: 90rem;
    }
}

.btn-cerrar {
    margin-top: -6rem;
    width: 80px;
    height: 40px;
    padding: 0;
    border-radius: 5px;
    color: v.$terceary_dk;
    font-weight: 400;
    background: v.$grey_lt;
    display: grid;
    place-items: center;
    &:hover {
        cursor: pointer;
    }
}