@use 'base/variables' as v;
@use 'base/mixins' as m;

.video {
    background: url(../img/enchantedWoods_sq.jpg), v.$primary_dk;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 50rem;
    overflow: hidden;
    @include m.desck {
        max-height: 60rem;
    }
    .overlay {
        display: flex;
        flex-direction: column;
        justify-content: center; // flexcolumn
        position: absolute;
        mix-blend-mode: hard-light;
        background: v.$gradient_01_a;
        height: 100%;
        width: 100%;
    }
    video {
        width: auto;
        height: 100%;
        display: block;
        @include m.tablet {
            width: 100%;
        }
    }
}

.contenido-video {
    text-align: center;
    z-index: 2;
    h3 {
        color: v.$white;
        font-size: 3rem;
        font-weight: 100;
        margin: 0;
        text-shadow: v.$tetx_sh;
        @include m.tablet {
            font-size: 5.4rem;
        }
    }
    h2 {
        display: inline-block;
        color: v.$white;
        font-size: 6rem;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 0 2rem 0;
        padding: 0 0 1rem 0;
        border-bottom: 1px solid v.$white;
        text-shadow: v.$tetx_sh;
        @include m.tablet {
            font-size: 10rem;
        }
    }
    p {
        color: v.$white;
        font-size: 1.7rem;
        letter-spacing: 5px;
        font-weight: 400;
        margin: 0;
        text-shadow: v.$tetx_sh;
        @include m.tablet {
            font-size: 3rem;
            letter-spacing: 7px;
        }
    }
}