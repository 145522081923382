@use 'base/variables' as v;
@use 'base/mixins' as m;

.social {
    background: url(../img/bg_ibookpor_ses_b.jpg), v.$primary_dk;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 7rem 0;
    margin-bottom: 5rem;
    color: v.$white;
    border-top: 1rem solid v.$primary_dk;
    border-radius: 10rem 0 10rem 0;
}

.social_cont {
    min-height: 40rem;
    padding: 2rem;
    border-radius: 10rem;
    background: rgba(15, 38, 70, 0.723);
    @include m.desck {
        display: grid;
        grid-template-columns: 20% 80%;
        gap: 3rem;
    }
    span {
        color: v.$secondary_lt;
    }
    .social_text,
    .social_img {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .icon{
        width: 4rem;
        stroke: none;
        fill: v.$white;
    }
    .social_text{
        h2 {
            margin: 1rem 0;
        }
        p {
            color: v.$white;
        }
    }
    .social_img {
        picture {
            border-radius: 50%;
            margin: 2rem auto;
            width: 150px;
        }
    }
}